import { Power1, Power2 } from 'gsap';

export function animateImageLeft(tl, svgLine, svgCircle, title, description, image, container) {
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 0.9, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.9,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo('.description', 0.3, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, 0.3)
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      0.7,
      {
        opacity: 0,
        y: 15,
        ease: Power1.easeOut,
      },
      0,
      0.7
    )
    .staggerFrom(
      description,
      1.3,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      1.3
    )
    .staggerFrom(
      container,
      1.3,
      {
        x: -120,
        y: 20,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      1
    )
    .staggerTo(
      image,
      1.3,
      {
        x: '70px',
        y: '-70px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      1
    );
}

export function animateImageRight(tl, svgLine, svgCircle, image, container, title, desc) {
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 0.9, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.9,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo('.description', 0.3, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, 0.3)
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      0.7,
      {
        opacity: 0,
        y: 15,
        ease: Power1.easeOut,
      },
      0,
      0.7
    )
    .staggerFrom(
      desc,
      0.9,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      0.9
    )
    .staggerFrom(
      container,
      1.3,
      {
        x: 120,
        y: 30,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      1
    )
    .staggerTo(
      image,
      1.3,
      {
        x: '-70px',
        y: '-70px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      1
    );
}
