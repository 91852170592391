import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import { graphql } from 'gatsby';
import Observer from 'react-intersection-observer';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import '../sass/_blueprint-toolbox.scss';
import LightBluekey from '../../static/assets/light_blue_key.svg';
import YellowKey from '../../static/assets/yellow_key.svg';
import ConsultingServices from '../../static/img/blueprint_toolbox/consulting_services.png';
import ResearchMethods from '../../static/img/blueprint_toolbox/research_methods.jpeg';
import * as globalAnimation from '../animations/globalAnimation';
import { animateImageRight, animateImageLeft } from '../animations/BlueprintToolbox';

export default class BlueprintToolbox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    animateImageRight(
      this.tl,
      '.svg-line__line_lightblue',
      '.svg_circle_lightblue',
      '.toolbox-image-one',
      '.toolbox-bg-one',
      '#title',
      '#desc'
    );
  }

  researchToolAnimation = InView => {
    const newTL = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animateImageLeft(
        newTL,
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '#title-research',
        '#desc-research',
        '.research-img',
        '.research-bg'
      );
    }
  };

  render() {
    const {
      data: {
        markdownRemark: {
          frontmatter: { title, ContentOne, ContentTwo },
        },
      },
    } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Blueprint Toolbox - Blueprint Toolbox" />
        <Container fluid className="main_wrapper section-one">
          <Row>
            <Col>
              <div className="wrapper">
                <div className="page_heading_section text-center">
                  <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                  <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                </div>
              </div>
            </Col>
          </Row>
          <div className="wrapper">
            <Row noGutters>
              <Col xs={12} lg={{ span: 6 }} className="order-2 order-lg-1">
                <div className="position-relative line line-horizontal px-2 pt-3 pt-lg-0 py-lg-0">
                  <h5 id="title" className="text-capitalize text-appcolor text-bold">
                    {ContentOne.title}
                    <span className="d-block">{ContentOne.title2}</span>
                  </h5>
                  <LightBluekey className="mb-0" />
                  <div id="desc" className="circle-dark-bullets-small ml-3">
                    <ul className="text-capitalize pl-0">
                      {ContentOne.lists.map(list => (
                        <li className="pl-2">{list.text}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={{ span: 4, offset: 2 }} className="order-1 order-lg-2">
                <div className="bg-appcolor-light lightblue_bg square mx-auto my-md-4 my-lg-0 toolbox-bg-one d-lg-block d-none">
                  <div className="consulting-img toolbox-image-one">
                    <img src={ConsultingServices} alt="" className="img-fluid toolbox-image" />
                  </div>
                </div>
                <div className="d-block d-lg-none">
                  <img src={ConsultingServices} alt="" className="img-fluid toolbox-image" />
                </div>
              </Col>
            </Row>
          </div>
          <div className="wrapper d-none d-lg-block">
            <Row noGutters className="py-lg-5 py-3">
              <Col xs={12} lg={{ span: 4 }} className="order-lg-1 order-2">
                <Observer onChange={this.researchToolAnimation} triggerOnce>
                  <div className="bg-orange square my-md-4 my-lg-0 research-bg  d-lg-block d-none">
                    <div className="research-img">
                      <img src={ResearchMethods} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="d-block d-lg-none">
                    <img src={ResearchMethods} alt="" className="img-fluid" />
                  </div>
                </Observer>
              </Col>
              <Col xs={12} lg={{ span: 5, offset: 3 }} className="order-1 order-lg-2">
                <div className="position-relative line line-horizontal px-2 py-3 py-lg-0">
                  <h5 className="text-capitalize text-appcolor text-bold" id="title-research">
                    research tools/methods
                  </h5>
                  <YellowKey className="mb-0" />
                  <div className="circle-dark-bullets-small" id="desc-research">
                    <ul className="pl-0 ml-3">
                      {ContentTwo.lists.map(list => (
                        <li className="pl-2">{list.text}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="wrapper d-lg-none pt-0">
            <Row noGutters>
              <Col xs={12} lg={{ span: 4 }}>
                <Observer onChange={this.researchToolAnimation} triggerOnce>
                  <div className="bg-orange square my-md-4 my-lg-0 research-bg  d-lg-block d-none">
                    <div className="research-img">
                      <img src={ResearchMethods} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="d-block d-lg-none">
                    <img src={ResearchMethods} alt="" className="img-fluid" />
                  </div>
                </Observer>
              </Col>
              <Col xs={12} lg={{ span: 5, offset: 3 }}>
                <div className="position-relative line line-horizontal px-2 pt-3">
                  <h5 className="text-capitalize text-appcolor text-bold" id="title-research">
                    research tools/methods
                  </h5>
                  <YellowKey className="mb-0" />
                  <div className="circle-dark-bullets-small" id="desc-research">
                    <ul className="pl-0 ml-3">
                      {ContentTwo.lists.map(list => (
                        <li className="pl-2">{list.text}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Layout>
    );
  }
}
export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "blueprint-toolbox" } }) {
      frontmatter {
        page
        title
        ContentOne {
          title
          title2
          image {
            publicURL
          }
          lists {
            text
          }
        }
        ContentTwo {
          title
          image {
            publicURL
          }
          lists {
            text
          }
        }
      }
    }
  }
`;
